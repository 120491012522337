import { Container } from "@mui/system";
import React, { Component } from "react";
import UnderConstruction from "../componets/common/UnderConstruction";
const PatientDetails = () => {
  return (
    <Container>
      <h1>Personal Details</h1>
      <div>Below are the details we have on file for your patient profile.</div>
      <UnderConstruction></UnderConstruction>
    </Container>
  );
};

export default PatientDetails;
