import logo from "./logo.svg";
import "./App.css";
import PatientAppintment from "./pages/PatientAppointment";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./componets/common/Footer";
import Header from "./componets/common/Header";
import { Container } from "@mui/material";
import NotFpound from "./pages/NotFound";
import NotFound from "./pages/NotFound";
import PatientLogin from "./pages/PatientLogin";
import PatientDetails from "./pages/PatientDetails";
import PatientAppointmentSchedule from "./pages/PatientAppointmentSchedule";
import CancelPatientAppointment from "./pages/CancelPatientAppointment";
import ViewPatientAppointmentDetails from "./pages/ViewPatientAppointmentDetails";
//import { firebase } from "./firebase";

function App() {
  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#02065b",
      },
      secondary: {
        main: "#859111",
      },
      background: {
        default: "#e9e9e9",
      },
      text: {
        primary: "#242a56",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <ToastContainer />
      <Container sx={{ minHeight: `calc(100vh - (156px + 104px))` }}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<PatientLogin />} />
            <Route
              path="/patient-details/:patientId"
              element={<PatientDetails />}
            />
            <Route path="/" element={<PatientAppintment />} />
            <Route
              path="/cancel-patient-appointment/:appointmentId/:patientId"
              element={<CancelPatientAppointment />}
            />
            <Route
              path="/patient-appointment-schedule/:patientId"
              element={<PatientAppointmentSchedule />}
            />
            <Route
              path="/view-appointment-details/:appointmentId/:patientId"
              element={<ViewPatientAppointmentDetails />}
            />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<PatientAppintment />} />
            <Route element={<Navigate to="/not-found" replace />} />
          </Routes>
        </BrowserRouter>
      </Container>
      <Footer></Footer>
    </ThemeProvider>
  );
}

export default App;
