const practitioners = [
  {
    id: 30,
    practitionerName: "Coco Lam",
    practitionerType: "Physiotherapy",
    parctitionerTypeId: 3,
    AvailableSlots: [
      { start: "09:00", end: "09:30" },
      { start: "09:30", end: "10:00" },
      { start: "11:00", end: "11:30" },
      { start: "11:30", end: "12:00" },
      { start: "13:00", end: "13:30" },
      { start: "14:00", end: "14:30" },
      { start: "16:00", end: "16:30" },
    ],
  },
  {
    id: 21,
    practitionerName: "Zuva Faranisi",
    practitionerType: "GP",
    parctitionerTypeId: 1,
    AvailableSlots: [
      { start: "09:00", end: "09:30" },
      { start: "09:30", end: "10:00" },
      { start: "11:00", end: "11:30" },
      { start: "11:30", end: "12:00" },
      { start: "13:00", end: "13:30" },
      { start: "14:00", end: "14:30" },
      { start: "16:00", end: "16:30" },
    ],
  },
  {
    id: 121,
    practitionerName: "David Mmope",
    practitionerType: "GP",
    parctitionerTypeId: 1,
    AvailableSlots: [
      { start: "09:00", end: "09:30" },
      { start: "09:30", end: "10:00" },
      { start: "11:00", end: "11:30" },
      { start: "11:30", end: "12:00" },
      { start: "13:00", end: "13:30" },
      { start: "14:00", end: "14:30" },
      { start: "16:00", end: "16:30" },
    ],
  },
  {
    id: 78,
    practitionerName: "Maxine Palsania",
    practitionerType: "Biokinetics",
    parctitionerTypeId: 2,
    AvailableSlots: [
      { start: "09:00", end: "09:30" },
      { start: "09:30", end: "10:00" },
      { start: "11:00", end: "11:30" },
      { start: "11:30", end: "12:00" },
      { start: "13:00", end: "13:30" },
      { start: "14:00", end: "14:30" },
      { start: "16:00", end: "16:30" },
    ],
  },
  {
    id: 39,
    practitionerName: "Jerry Mncube",
    practitionerType: "Biokinetics",
    parctitionerTypeId: 2,
    AvailableSlots: [
      { start: "09:00", end: "09:30" },
      { start: "09:30", end: "10:00" },
      { start: "11:00", end: "11:30" },
      { start: "11:30", end: "12:00" },
      { start: "13:00", end: "13:30" },
      { start: "14:00", end: "14:30" },
      { start: "16:00", end: "16:30" },
    ],
  },
  {
    id: 77,
    practitionerName: "Tendai Mtize",
    practitionerType: "Physiotherapy",
    parctitionerTypeId: 3,
    AvailableSlots: [
      { start: "09:00", end: "09:30" },
      { start: "09:30", end: "10:00" },
      { start: "11:00", end: "11:30" },
      { start: "11:30", end: "12:00" },
      { start: "13:00", end: "13:30" },
      { start: "14:00", end: "14:30" },
      { start: "16:00", end: "16:30" },
    ],
  },
];

export async function getPractitionerAvailability(
  parctitionerTypeId,
  locationId,
  appointmentDate
) {
  return practitioners.filter(
    (x) => x.parctitionerTypeId === parctitionerTypeId
  );
}

export default { getPractitionerAvailability };
