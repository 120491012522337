import { Container } from "@mui/system";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PatientAppointmentDetails from "../componets/common/PatientAppointmentDetails";
const ViewPatientAppointmentDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  // const handleCancelClick = ({ appointmentId, patientId }) => {
  //   );
  // };
  return (
    <Container>
      <h1>Appointment Details</h1>
      <div>Below are the details for your appointment.</div>

      <PatientAppointmentDetails
        appointmentId={params.appointmentId}
        patientId={params.patientId}
        cancellable={true}
      />
    </Container>
  );
};

export default ViewPatientAppointmentDetails;
