import * as React from "react";
import Box from "@mui/material/Box";
import {
  Container,
  Divider,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import tbcLogo from "../../assets/images/TBC-Logo-Rectangle.png";
import blueLogo from "../../assets/images/A7-logo.png";
import { Link } from "@mui/material";

const Footer = () => {
  return (
    <Box component="footer">
      <Divider />
      <Box
        sx={{
          py: 4,
          px: 4,
          display: { xs: "block", sm: "flex" },
          alignItems: { sm: "center" },
          justifyContent: { sm: "space-between" },
          minHeight: 50,
        }}
      >
        <Typography color="text.secondary" variant="body2">
          Copyright © {new Date().getFullYear()} A7 Health
        </Typography>
        <Box sx={{ py: { xs: 2, sm: 0 } }}>
          <Stack spacing={2} direction="row">
            <img
              alt="A7 Logo"
              src={require("../../assets/images/A7-logo.png")}
              height="50vh"
            ></img>
            <img
              alt="A7 Logo"
              src={require("../../assets/images/TBC-Logo-Rectangle.png")}
              height="40vh"
            ></img>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
