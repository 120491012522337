import {
  Alert,
  Avatar,
  Chip,
  Grid,
  ListItem,
  Paper,
  styled,
} from "@mui/material";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import { Stack } from "@mui/system";
import React, { Component, useEffect } from "react";
import { toast } from "react-toastify";
import { stringAvatar } from "../Utils/stringAvatar";
import { stringToColor } from "../Utils/stringToColor";
import { Label } from "@mui/icons-material";
const PractitionerAvailableTimeslots = ({
  practitionerDetails,
  onTimeSlotSelected,
  selectedSlotDetails,
  onDeleteSelectedTimeslot,
}) => {
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  useEffect(() => {
    console.log(practitionerDetails);
  }, []);

  return (
    <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={2} md={1}>
          <Avatar
            sx={{
              bgcolor: stringToColor(practitionerDetails.practitionerName),
            }}
          >
            {stringAvatar(practitionerDetails.practitionerName)}
          </Avatar>
        </Grid>
        <Grid item xs={10} md={11}>
          <h4>
            {practitionerDetails.practitionerName} -{" "}
            {practitionerDetails.practitionerType}
          </h4>
        </Grid>
        <Grid container>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              listStyle: "none",
              p: 0.5,
              m: 0,
            }}
            component="ul"
          >
            {practitionerDetails.AvailableSlots.map((x, i) => {
              let icon;
              if (
                !(
                  { ...selectedSlotDetails }.practitionerId ===
                    { ...practitionerDetails }.id &&
                  { ...selectedSlotDetails }.start === x.start
                )
              ) {
                icon = <MoreTimeIcon />;
              }
              return (
                <ListItem key={`timeslot_${i}`}>
                  <Chip
                    icon={icon}
                    spacing={1}
                    size="medium"
                    variant="filled"
                    color={icon === undefined ? "success" : "primary"}
                    label={`${x.start}`}
                    onClick={() => {
                      onTimeSlotSelected(
                        x,
                        practitionerDetails.id,
                        practitionerDetails.practitionerName
                      );
                    }}
                    onDelete={
                      icon !== undefined
                        ? undefined
                        : () => {
                            onDeleteSelectedTimeslot();
                          }
                    }
                  />
                </ListItem>
              );
            })}
          </Paper>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PractitionerAvailableTimeslots;
