import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { Component, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PatientAppointmentDetails from "../componets/common/PatientAppointmentDetails";
import UnderConstruction from "../componets/common/UnderConstruction";
import { cancelAppointment } from "../services/appointmentService";
const CancelPatientAppointment = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const handleCancellation = async () => {
    setLoading(true);
    await cancelAppointment(params.appointmentId)
      .then(({ data, status }) => {
        if (status == 200) {
          setLoading(false);
          toast.success("Appointment has been cancelled");
          setCancelled(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          "There was an error cancelling your appointment. Please call the practice",
          {
            autoClose: 5000,
          }
        );

        console.error(error);
      });
  };
  return (
    <Container>
      <h1>Cancel Your Appointment</h1>
      <Typography sx={{ mt: 1 }}>
        Details of the appointment you want to cancel.{" "}
        <strong>Please read carefully</strong> before you proceed!
      </Typography>
      <Grid container spacing={2}>
        {loading && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button
            color="error"
            variant="contained"
            onClick={handleCancellation}
            disabled={cancelled}
          >
            Cancel Appointment
          </Button>
        </Grid>
      </Grid>
      <PatientAppointmentDetails
        appointmentId={params.appointmentId}
        patientId={params.patientId}
      />
    </Container>
  );
};

export default CancelPatientAppointment;
