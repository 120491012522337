import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { loggerUrl, version } from "../config";

function init() {
  Sentry.init({
    dsn: loggerUrl,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: version,
    environment: "development-test",
  });
}

function log(error) {
  Sentry.captureException(error);
}

export default {
  init,
  log,
};
