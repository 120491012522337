import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { Container } from "@mui/system";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { getAppointmentDetails } from "../../services/appointmentService";
import { validateIdNumber } from "south-african-id-validator";
import PatientDetails from "./PatientDetails";
import { useNavigate } from "react-router-dom";

const PatientAppointmentDetails = ({
  patientId,
  appointmentId,
  appointment,
  editable = false,
  cancellable = false,
}) => {
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    if (appointment === undefined) {
      const getData = async () => {
        var response = await getAppointmentDetails(patientId, appointmentId);
        console.log("Response", response);
        if (response.status === 200) {
          setAppointmentDetails(response.data);
          return;
        }
        return;
      };

      getData();
      return;
    } else {
      setAppointmentDetails(appointment);
    }
  }, [patientId, appointmentId]);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <h4>Appointment Details</h4>
        </Grid>
        <Grid item md={6} xs={12}>
          <List
            component={Stack}
            direction="column"
            dense={true}
            sx={{ width: "100%" }}
          >
            <ListItem>
              <ListItemText
                primary="Treatment"
                secondary={appointmentDetails?.treatmentType}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Treatment Type"
                secondary={appointmentDetails?.treatmentType}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Practitioner"
                secondary={appointmentDetails?.practitionerName}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Branch"
                secondary={appointmentDetails?.locationName}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item md={6} xs={12}>
          <List
            component={Stack}
            direction="column"
            dense={true}
            sx={{ width: "100%" }}
          >
            <ListItem>
              <ListItemText
                primary="Date"
                secondary={dayjs(appointmentDetails?.appointmentDate).format(
                  "DD MMMM YYYY"
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Appointment Start "
                secondary={dayjs(appointmentDetails?.start).format("HH:mm")}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Appointment End "
                secondary={dayjs(appointmentDetails?.end).format("HH:mm")}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid container md={12} xs={12} spacing={2}>
          <Grid item xs="auto" />
          {editable && (
            <Grid item md={3} xs={12}>
              <Button variant="contained" color="success">
                Edit Appointment
              </Button>
            </Grid>
          )}
          {cancellable && (
            <Grid item md={3} xs={12}>
              <Button
                variant="contained"
                color="error"
                onClick={() =>
                  navigate(
                    `/cancel-patient-appointment/${appointmentId}/${patientId}`
                  )
                }
              >
                Cancel Appointment
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <PatientDetails appointment={appointmentDetails} />
    </Container>
  );
};

export default PatientAppointmentDetails;
