import { Typography } from "@mui/material";
import React, { Component } from "react";
const UnderConstruction = () => {
  return (
    <>
      <Typography>This page is still under construction</Typography>
    </>
  );
};

export default UnderConstruction;
