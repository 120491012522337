import { Container } from "@mui/material";
import React, { Component } from "react";
const NotFound = () => {
  return (
    <Container>
      <h1>Not Found</h1>
      <div>Ooops the page you are looking for has not been found!</div>
      <div>Please try again later.</div>
    </Container>
  );
};

export default NotFound;
