const locations = [
  { id: 1, name: "Sandton", province: "Gauteng", country: "SA" },
  { id: 2, name: "Kempton Park ", province: "Gauteng", country: "SA" },
  { id: 3, name: "Polokwane", province: "Limpopo", country: "SA" },
  { id: 4, name: "Roodeport", province: "Gauteng", country: "SA" },
  { id: 5, name: "Port Elizabeth", province: "Eastern Cape", country: "SA" },
  { id: 6, name: "Bloemfontein", province: "Free State", country: "SA" },
  { id: 7, name: "Rustenburg", province: "Notrh West", country: "SA" },
];
export async function getLocations() {
  return locations;
}

export default { getLocations };
