import { Container } from "@mui/material";
import React, { Component } from "react";
import UnderConstruction from "../componets/common/UnderConstruction";
const PatientAppointmentSchedule = () => {
  return (
    <Container>
      <h1>Schedule of Past and Future Appointments</h1>
      <UnderConstruction></UnderConstruction>
    </Container>
  );
};

export default PatientAppointmentSchedule;
