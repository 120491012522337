import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import GlobalStyles from "@mui/material/GlobalStyles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import GitHubIcon from "@mui/icons-material/GitHub";
import { Link } from "@mui/material";
import whiteLogo from "../../assets/images/A7-white-logo.png";
import blueLogo from "../../assets/images/A7-logo.png";
import whiteAPlusLogo from "../../assets/images/Aplus-white-logo.png";
import blueAPlusLogo from "../../assets/images/Aplus-logo.png";

const MyHeader = styled("header")(({ theme }) => [
  {
    position: "sticky",
    top: 0,
    transition: theme.transitions.create("top"),
    zIndex: theme.zIndex.appBar,
    backdropFilter: "blur(20px)",
    boxShadow: `inset 0px -1px 1px ${(theme.vars || theme).palette.grey[100]}`,
    backgroundColor: "primary.main",
  },
]);

const HEIGHT = 56;

export default function Header() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        minHeight: HEIGHT,
        backgroundColor: "primary.main",
      }}
    >
      <Box
        aria-label="Go to homepage"
        sx={{ lineHeight: 0, mr: 2, pb: 2, pt: 1 }}
      >
        <img
          alt="A7 Logo"
          src={require("../../assets/images/Aplus-white-logo.png")}
          height="80vh"
        ></img>
      </Box>
      <Box sx={{ display: { xs: "none", md: "initial" } }}></Box>
      <Box sx={{ ml: "auto" }} />

      <Box sx={{ display: { md: "none" }, ml: 1 }}></Box>
    </Box>
  );
}
