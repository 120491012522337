const treatmentTypes = [
  { id: 3, name: "Physiotherapy", active: true },
  { id: 2, name: "Biokinetics", active: true },
  { id: 1, name: "GP", active: false },
  { id: 4, name: "Exercise Class", active: true },
  { id: 5, name: "Back and Neck Pain", active: true },
];
const treatmentSubTypes = [
  { id: 1, name: "Antenatal exercise class", treatmentTypeId: 4 },
  { id: 2, name: "Post-Partum  exercise class", treatmentTypeId: 4 },
  { id: 3, name: "General  exercise class", treatmentTypeId: 4 },
  { id: 4, name: "First assessment and treatment", treatmentTypeId: 3 },
  { id: 5, name: "Follow up treatment", treatmentTypeId: 3 },
  { id: 6, name: "First assessment and treatment", treatmentTypeId: 2 },
  { id: 7, name: "Follow up treatment", treatmentTypeId: 2 },
  { id: 8, name: "DBC treatment for back pain", treatmentTypeId: 5 },
  { id: 9, name: "Home visit", treatmentTypeId: 3 },
  { id: 10, name: "Home visit", treatmentTypeId: 2 },
  { id: 11, name: "GP Consult", treatmentTypeId: 1 },
];

export async function getTreatmentTypes() {
  return treatmentTypes.filter((x) => x.active === true);
}

export async function getTreatmentSubTypes(treatmentTypeId) {
  return treatmentSubTypes.filter((x) => x.treatmentTypeId === treatmentTypeId);
}

export default { getTreatmentTypes, getTreatmentSubTypes };
