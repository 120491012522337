export function stringAvatar(name) {
  let initials = "";
  if (name !== undefined) {
    const parts = name.split(" ");
    initials =
      parts.length > 1
        ? `${parts[0].substring(0, 1)}${parts[1].substring(0, 1)}`
        : `${parts[0].substring(0, 1)}`;
  }

  return initials;
}
