import { Grid, List, ListItem, ListItemText, Stack } from "@mui/material";
import { Container } from "@mui/system";
import dayjs from "dayjs";
import React, { useState } from "react";
import { validateIdNumber } from "south-african-id-validator";

const PatientDetails = ({ appointment }) => {
  const [appointmentDetails, setAppointmentDetails] = useState(appointment);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <h4>Patient Details</h4>
        <List
          component={Stack}
          direction="column"
          dense={true}
          sx={{ width: "100%" }}
        >
          <ListItem>
            <ListItemText
              primary="Full Name"
              secondary={`${appointmentDetails?.name} ${appointmentDetails?.surname}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                appointmentDetails?.idType !== null
                  ? `${appointmentDetails?.idType} #`
                  : validateIdNumber(appointmentDetails?.idNumber)
                  ? "SA ID #"
                  : "Passport #"
              }
              secondary={appointmentDetails?.idNumber}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Date of Birth"
              secondary={dayjs(appointmentDetails?.dob).format("DD MMM YYYY")}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Mobile Number"
              secondary={appointmentDetails?.mobileNumber}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Email Address"
              secondary={
                appointmentDetails?.emailAddress === null
                  ? "-"
                  : appointmentDetails?.emailAddress
              }
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default PatientDetails;
