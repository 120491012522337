import { Container } from "@mui/system";
import React, { Component } from "react";
const PatientLogin = () => {
  return (
    <Container>
      <h1>Login</h1>
    </Container>
  );
};

export default PatientLogin;
