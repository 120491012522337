import {
  Box,
  Button,
  Grid,
  Paper,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
} from "@mui/material";
import dayjs from "dayjs";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import treatmentService from "../services/treatmentService";
import practitionerAvailabilityService from "../services/practitionerAvailabilityService";
import PractitionerAvailableTimeslots from "../componets/PractitionerAvailableTimeslots";
import locationService from "../services/locationService";
import patientLeadsService, {
  createLead,
} from "../services/patientLeadsService";
import { toast } from "react-toastify";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { bookAppointment } from "../services/appointmentService";
import { v4 } from "uuid";
import { parseDOB, validateIdNumber } from "south-african-id-validator";

const PatientAppintment = () => {
  const minwidth = 300;
  const [treatmentTypes, setTreatmentTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [treatmentSubTypes, setTreatmentSubTypes] = useState([]);
  const [practitioners, setPractitioners] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedTreatmentTypeId, setSelectedTreatmentTypeId] = useState("");
  const [selectedTreatmentSubTypeId, setSelectedTreatmentSubTypeId] =
    useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
  const [selectedAppointmentDate, setSelectedAppointmentDate] = useState(
    new Date()
  );
  const [errors, setErrors] = useState({});
  const [uuid, setUuid] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [patientDetails, setPatientDetails] = useState({
    name: "",
    surname: "",
    idType: "ID",
    idNumber: "",
    passportNumber: "",
    emailAddress: "",
    mobileNumber: "",
    dob: "",
  });
  //   const object = Joi.object({
  //     name: Joi.string().required().label("Name"),
  //     surname: Joi.string().required().label("Surname"),
  //     mobileNumber: Joi.required().label("Mobile number"),
  //     idNumber: Joi.alternatives().conditional('idType', {is: 'ID', then: Joi.required().min(13).max(13).label("ID number")}),
  //     passportNumber: Joi.alternatives().conditional('idType', {is: 'Passport', then: Joi.required().min(5).label("Passport number")})
  // });

  // const handleSavePatientDetails = (event) => {
  //   const { name, value } = event.target;
  //   let errorData = { ...errors };
  //   const errorMessage = validateProperty(event);

  //   if (errorMessage) {
  //     errorData[name] = errorMessage;
  //   } else {
  //     delete errorData[name];
  //   }
  //   let studentData = { ...studentDetails };
  //   studentData[name] = value;
  //   setStudentDetails(studentData);
  //   setErrors(errorData);
  // };

  const checkValidity = () => {
    if (
      patientDetails.name !== "" &&
      patientDetails.surname !== "" &&
      patientDetails.mobileNumber !== "" &&
      patientDetails.emailAddress !== ""
    ) {
      if (
        (patientDetails.idType === "ID" && patientDetails.idNumber !== "") ||
        (patientDetails.idType === "Passport" &&
          patientDetails.passportNumber !== "")
      ) {
        setIsValid(true);
        return;
      }
      setIsValid(false);
      return;
    }
    setIsValid(false);
  };

  const handlePatientDetails = (e) => {
    const { value, name } = e.target;
    setPatientDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePatientDoB = (value) => {
    setPatientDetails((prevState) => ({
      ...prevState,
      ["dob"]: dayjs(value).format(),
    }));
  };

  const handleNext = async () => {
    checkValidity();
    if (activeStep == steps.length - 2) {
      const response = await createLead(uuid, {
        id: uuid,
        appointmentDate: dayjs(selectedAppointmentDate).format(),
        locationId: selectedLocationId,
        locationName: locations.find((x) => x.id === selectedLocationId)?.name,
        practitionerType: selectedTreatmentTypeId,
        treatment: treatmentTypes.find((x) => x.id === selectedTreatmentTypeId)
          ?.name,
        treatmentType: treatmentSubTypes.find(
          (x) => x.id === selectedTreatmentSubTypeId
        )?.name,
        ...selectedTimeSlot,
        ...patientDetails,
      });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setUuid(v4());
    setSelectedLocationId("");
    setSelectedTreatmentTypeId("");
    setSelectedTreatmentSubTypeId("");
    setSelectedTimeSlot({});
    setSelectedAppointmentDate(new Date());
    setErrors({});
    setIsValid(false);
    setPractitioners([]);
    setPatientDetails({
      name: "",
      surname: "",
      idType: "ID",
      idNumber: "",
      passportNumber: "",
      emailAddress: "",
      mobileNumber: "",
      dob: "",
    });

    setActiveStep(0);
  };

  const handleToggleIdType = (idType) => {
    const field = idType === "ID" ? "idNumber" : "passportNumber";
    setPatientDetails((prevState) => ({
      ...prevState,
      [field]: "",
    }));
  };

  const handleTimeSlotSelected = ({ start, end }, id, name) => {
    let timeslot = { start, end, practitionerId: id, practitionerName: name };
    setSelectedTimeSlot(timeslot);
  };

  const handleAppointmentDateSelected = (dateValue) => {
    handleDeletedTimeslot();
    setSelectedTreatmentSubTypeId("");
    setSelectedTreatmentTypeId("");
    setAppointmentDate(dateValue);
    setPractitioners([]);
  };

  const handleBranchSelected = (value) => {
    handleDeletedTimeslot();
    setPractitioners([]);
    setSelectedTreatmentSubTypeId("");
    setSelectedTreatmentTypeId("");
    setSelectedLocationId(value);
  };

  const handleDeletedTimeslot = () => {
    setSelectedTimeSlot({});
  };

  useEffect(() => {
    setUuid(v4());
    async function getLocations() {
      const retVal = await locationService.getLocations();
      setLocations(retVal);
    }

    async function getTreatmentTypes() {
      const retVal = await treatmentService.getTreatmentTypes();
      setTreatmentTypes(retVal);
    }

    getLocations().catch(console.error());
    getTreatmentTypes().catch(console.error());
  }, []);

  const getTreatmentSubTypes = async (value) => {
    const retVal = await treatmentService.getTreatmentSubTypes(value);
    setSelectedTreatmentTypeId(value);
    setSelectedTreatmentSubTypeId("");
    setTreatmentSubTypes(retVal);
    setPractitioners([]);
  };

  const handleTreatmentTypeSelection = async (value) => {
    setSelectedTreatmentSubTypeId(value);
    await getPractitioners();
  };

  const getPractitioners = async () => {
    const retVal =
      await practitionerAvailabilityService.getPractitionerAvailability(
        selectedTreatmentTypeId,
        selectedLocationId,
        selectedAppointmentDate
      );
    setPractitioners(retVal);
  };

  const setAppointmentDate = async (selectedDate) => {
    setSelectedAppointmentDate(selectedDate);
    await getPractitioners();
  };

  const handleSubmit = async () => {
    setLoading(true);
    await bookAppointment({
      id: uuid,
      appointmentDate: dayjs(selectedAppointmentDate).format(),
      locationId: selectedLocationId,
      locationName: locations.find((x) => x.id === selectedLocationId)?.name,
      practitionerType: selectedTreatmentTypeId,
      treatment: treatmentTypes.find((x) => x.id === selectedTreatmentTypeId)
        ?.name,
      treatmentType: treatmentSubTypes.find(
        (x) => x.id === selectedTreatmentSubTypeId
      )?.name,
      ...selectedTimeSlot,
      ...patientDetails,
    })
      .then(({ data, status }) => {
        if (status === 200) {
          setLoading(false);
          handleNext();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("There was an error booking your appointment.", {
          autoClose: 5000,
          position: "bottom-left",
        });

        console.error("Error occured", error);
      });
  };

  const steps = [
    {
      label: "Book Appointment",
      description: (
        <>
          <div>
            <FormControl sx={{ m: 1, minWidth: minwidth }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Appointment Date"
                  inputFormat="DD MMM YYYY"
                  value={selectedAppointmentDate}
                  onChange={(newValue) => {
                    handleAppointmentDateSelected(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: minwidth }}>
              <InputLabel id="branch-label">Branch</InputLabel>
              <Select
                labelId="branch-label"
                id="branch-select"
                value={selectedLocationId}
                label="Branch"
                onChange={(e) => {
                  handleBranchSelected(e.target.value);
                }}
              >
                {locations.length > 0 &&
                  locations.map((x) => {
                    return (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: minwidth }}>
              <InputLabel id="practitioner-label">Practitioner Type</InputLabel>
              <Select
                labelId="practitioner-label"
                id="practitioner-select"
                value={selectedTreatmentTypeId}
                label="Practitioner Type"
                onChange={(e) => {
                  handleDeletedTimeslot();
                  getTreatmentSubTypes(e.target.value);
                }}
              >
                {treatmentTypes.length > 0 &&
                  treatmentTypes.map((x) => {
                    return (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: minwidth }}>
              <InputLabel id="treatment-type-label">Treatment Type</InputLabel>
              <Select
                labelId="treatment-type-label"
                id="treatment-type-select"
                value={selectedTreatmentSubTypeId}
                label="Treatment Type"
                onChange={(e) => handleTreatmentTypeSelection(e.target.value)}
              >
                {treatmentSubTypes.length > 0 &&
                  treatmentSubTypes.map((x) => {
                    return (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          {practitioners.map((x, i) => {
            return (
              <div>
                {
                  <PractitionerAvailableTimeslots
                    key={`practitioner_${x.id}`}
                    practitionerDetails={x}
                    onTimeSlotSelected={handleTimeSlotSelected}
                    selectedSlotDetails={selectedTimeSlot}
                    onDeleteSelectedTimeslot={handleDeletedTimeslot}
                  ></PractitionerAvailableTimeslots>
                }
              </div>
            );
          })}
        </>
      ),
    },
    {
      label: "Patient Details",
      description: (
        <>
          <div>
            <FormControl sx={{ m: 1, minWidth: minwidth }}>
              <TextField
                required
                id="outlined-required"
                label="Name"
                name="name"
                value={patientDetails.name}
                onChange={(e) => handlePatientDetails(e)}
              />
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: minwidth }}>
              <TextField
                required
                id="outlined-required"
                label="Surname"
                name="surname"
                defaultValue={patientDetails.surname}
                onChange={(e) => handlePatientDetails(e)}
              />
            </FormControl>
          </div>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={patientDetails.idType}
                name="idType"
                label="ID Type"
                onChange={(e) => {
                  handleToggleIdType(e.target.value);
                  handlePatientDetails(e);
                }}
                row
              >
                <FormControlLabel
                  value="ID"
                  control={<Radio />}
                  label="SA ID"
                />
                <FormControlLabel
                  value="Passport"
                  control={<Radio />}
                  label="Passport"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: minwidth }}>
              <TextField
                required
                id="outlined-required"
                label={`${
                  patientDetails.idType.toLowerCase() === "id"
                    ? "ID"
                    : "Passport"
                } Number`}
                name={
                  patientDetails.idType.toLowerCase() === "id"
                    ? "idNumber"
                    : "passportNumber"
                }
                value={
                  patientDetails.idType.toLowerCase() === "id"
                    ? patientDetails.idNumber
                    : patientDetails.passportNumber
                }
                onChange={(e) => {
                  const { value } = e.target;
                  handlePatientDetails(e);
                  if (
                    patientDetails.idType.toLowerCase() === "id" &&
                    validateIdNumber(value)
                  ) {
                    handlePatientDoB(parseDOB(value));
                  }
                }}
              />
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: minwidth }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth"
                  inputFormat="DD MMM YYYY"
                  name="dob"
                  value={patientDetails.dob}
                  onChange={(e) => {
                    handlePatientDoB(dayjs(e).format());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: minwidth }}>
              <TextField
                required
                id="outlined-required"
                label="Mobile Number"
                name="mobileNumber"
                defaultValue={patientDetails.mobileNumber}
                onChange={(e) => handlePatientDetails(e)}
              />
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: minwidth }}>
              <TextField
                required
                id="outlined-required"
                label="Email Address"
                name="emailAddress"
                value={patientDetails.emailAddress}
                onChange={(e) => handlePatientDetails(e)}
              />
            </FormControl>
          </div>
        </>
      ),
    },
    {
      label: "Confirm Booking",
      description: (
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <h4>Patient Details</h4>
            <List
              component={Stack}
              direction="column"
              dense={true}
              sx={{ width: "100%" }}
            >
              <ListItem>
                <ListItemText
                  primary="Full Name"
                  secondary={`${patientDetails.name} ${patientDetails.surname}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${patientDetails.idType} #`}
                  secondary={
                    patientDetails.idType === "ID"
                      ? patientDetails.idNumber
                      : patientDetails.passportNumber
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Date of Birth"
                  secondary={dayjs(patientDetails.dob).format("DD MMM YYYY")}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Mobile Number"
                  secondary={patientDetails.mobileNumber}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Email Address"
                  secondary={patientDetails.emailAddress}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item md={6} xs={12}>
            <h4>Appointment Details</h4>

            <List
              component={Stack}
              direction="column"
              dense={true}
              sx={{ width: "100%" }}
            >
              <ListItem>
                <ListItemText
                  primary="Treatment"
                  secondary={
                    treatmentTypes.find((x) => x.id === selectedTreatmentTypeId)
                      ?.name
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Treatment Type"
                  secondary={
                    treatmentSubTypes.find(
                      (x) => x.id === selectedTreatmentSubTypeId
                    )?.name
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Practitioner"
                  secondary={selectedTimeSlot.practitionerName}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Branch"
                  secondary={
                    locations.find((x) => x.id === selectedLocationId)?.name
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Date"
                  secondary={dayjs(selectedAppointmentDate).format(
                    "DD MMMM YYYY"
                  )}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Appointment Time "
                  secondary={
                    selectedTimeSlot.start === undefined ? (
                      <Typography color={"error"}>
                        No time selected yet
                      </Typography>
                    ) : (
                      `${selectedTimeSlot?.start} to ${selectedTimeSlot?.end}`
                    )
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      ),
    },
  ];

  return (
    <>
      <h1>Book a Consultation</h1>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                {loading && (
                  <div md={6} xs={12}>
                    <LinearProgress />
                  </div>
                )}
                <div>
                  <Button
                    variant="contained"
                    onClick={
                      index === steps.length - 1 ? handleSubmit : handleNext
                    }
                    sx={{ mt: 1, mr: 1 }}
                    disabled={index === steps.length - 1 && !isValid}
                  >
                    {index === steps.length - 1
                      ? "Book Appointment"
                      : "Continue"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>
            All Done, Your next Appointment at A7 Health is booked.
          </Typography>
          <Typography>Please check your email for confirmation.</Typography>
          <Button
            color="success"
            variant="contained"
            onClick={handleReset}
            sx={{ mt: 1, mr: 1 }}
          >
            Book Another Appointment
          </Button>
        </Paper>
      )}
    </>
  );
};

export default PatientAppintment;
