import http from "./httpService";
import { apiEndpoint } from "../config";

const apppointmentEndpoint = `${apiEndpoint}/PatientAppointments`;

export async function bookAppointment(patientPayload) {
  console.log(JSON.stringify(patientPayload));
  return await http.post(apppointmentEndpoint, patientPayload);
}

export async function getAppointmentDetails(patientId, appointmentId) {
  return await http.get(
    `${apppointmentEndpoint}/${patientId}/${appointmentId}`
  );
}

export async function cancelAppointment(appointmentId) {
  return await http.delete(`${apppointmentEndpoint}/${appointmentId}`);
}

export async function listAppointments(mobileNumber, idNumber) {}
