import http from "./httpService";
import { apiEndpoint } from "../config";

const apppointmentEndpoint = `${apiEndpoint}/AppointmentLeads`;

export async function createLead(id, patientPayload) {
  return;
  const lead = {
    id,
    date: new Date(),
    payload: JSON.stringify(patientPayload),
  };
  console.log(lead);
  const content = await http.post(apppointmentEndpoint, lead);

  console.log("This is the lead return:", content);
  return content;
}

export async function deleteLead(id) {
  const { data } = await http.delete(`${apppointmentEndpoint}/${id}`);
}
